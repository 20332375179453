import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import { StaticQuery, graphql } from "gatsby"
import { Fade } from "react-awesome-reveal"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyLoad from "react-lazyload"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IndexPage = ({ lastPosts, lastReferences }) => {
  const settingsTestimonials = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    sliderToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
    pauseOnHover: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          speed: 500,
        },
      },
    ],
  }
  return (
    <Layout>
      <SEO title="Home" />

      <main className="home_page">
        <section className="home_section_top">
          <video
            width="100%"
            height="auto"
            loop
            autoPlay
            playsInline
            muted
            data-setup="{}"
          >
            <source
              src="https://forsecurewp.zenx.pl/wp-content/uploads/2021/04/hero-liquid.mp4"
              type="video/mp4"
            />
          </video>
          <img
            className="img-fluid hero-laptop"
            src={require("../assets/images/hero-laptop.png")}
            alt=""
          />
          <div className="inner">
            <h1>Zabezpieczamy Twój biznes w sieci</h1>
            <p>
              Zabezpieczamy, aktualizujemy i serwisujemy strony internetowe od 8
              lat
            </p>
            <Link to="/cennik/" className="btn_custom">
              &#60;Sprawdź/&#62;
            </Link>
          </div>
          <Link to="#uslugi" className="scroll_to"></Link>
        </section>

        <span className="anchor" id="uslugi"></span>
        <section className="home_section_services">
          <div className="container-fluid">
            <h2 className="home_header">&#60;Nasz3 usłu7i/&#62;</h2>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="image-holder">
                  <LazyLoad once>
                    <img
                      className="img-fluid"
                      src={require("../assets/images/home_ico_service_1.svg")}
                      alt=""
                    />
                  </LazyLoad>
                </div>
                <p>Zabezpieczenie</p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="image-holder">
                  <LazyLoad once>
                    <img
                      className="img-fluid"
                      src={require("../assets/images/home_ico_service_2.svg")}
                      alt=""
                    />
                  </LazyLoad>
                </div>
                <p>
                  Aktualizacja <br /> i rozwój strony
                </p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="image-holder">
                  <LazyLoad once>
                    <img
                      className="img-fluid"
                      src={require("../assets/images/home_ico_service_3.svg")}
                      alt=""
                    />
                  </LazyLoad>
                </div>
                <p>
                  Serwisowanie <br /> i optymalizacja
                </p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="image-holder">
                  <LazyLoad once>
                    <img
                      className="img-fluid"
                      src={require("../assets/images/home_ico_service_4.svg")}
                      alt=""
                    />
                  </LazyLoad>
                </div>
                <p>Pomoc techniczna</p>
              </div>
            </div>
            <div className="btn_wrapper">
              <Link to="/uslugi/" className="btn_custom">
                &#60;Sprawdź/&#62;
              </Link>
            </div>
          </div>
          <LazyLoad once>
            <img
              className="shape shape_1"
              src={require("../assets/images/home_service_shape_1.svg")}
              alt=""
            />
          </LazyLoad>
          <LazyLoad once>
            <img
              className="shape shape_2"
              src={require("../assets/images/home_service_shape_2.svg")}
              alt=""
            />
          </LazyLoad>
        </section>

        <section className="home-advantages">
          <div className="container-fluid">
            <h2 className="home_header">&#60;Co zyskuj3sz/&#62;</h2>
            <div className="row">
              {
                // <div className="col-md-2">
                //   <Fade delay={150} direction={"left"} triggerOnce>
                //     <div className="home-advantages__thumb">
                //       <img
                //         src={require("../assets/images/home-advantages-1.svg")}
                //         alt=""
                //       />
                //     </div>
                //     <p>Rejestracja</p>
                //   </Fade>
                // </div>
              }
              <div className="col-md">
                <Fade delay={300} direction={"left"} triggerOnce>
                  <div className="home-advantages__thumb">
                    <img
                      src={require("../assets/images/home-advantages-2.svg")}
                      alt=""
                    />
                  </div>
                  <p>Audyt bezpieczeństwa swojej strony internetowej</p>
                </Fade>
              </div>
              <div className="col-md">
                <Fade delay={450} direction={"left"} triggerOnce>
                  <div className="home-advantages__thumb">
                    <img
                      src={require("../assets/images/home-advantages-3.svg")}
                      alt=""
                    />
                  </div>
                  <p>Opiekę doświadczonych programistów</p>
                </Fade>
              </div>
              <div className="col-md">
                <Fade delay={600} direction={"left"} triggerOnce>
                  <div className="home-advantages__thumb">
                    <img
                      src={require("../assets/images/home-advantages-4.svg")}
                      alt=""
                    />
                  </div>
                  <p>Regularne backupy i aktualizacje</p>
                </Fade>
              </div>
              <div className="col-md">
                <Fade delay={750} direction={"left"} triggerOnce>
                  <div className="home-advantages__thumb">
                    <img
                      src={require("../assets/images/home-advantages-5.svg")}
                      alt=""
                    />
                  </div>
                  <p>
                    Możliwość rozwoju swojej strony w ramach przysługujących Ci
                    godzin abonamentowych
                  </p>
                </Fade>
              </div>
              <div className="col-md">
                <Fade delay={900} direction={"left"} triggerOnce>
                  <div className="home-advantages__thumb">
                    <img
                      src={require("../assets/images/home-advantages-6.svg")}
                      alt=""
                    />
                  </div>
                  <p>
                    Opcję zlecania dodatkowych prac przez dedykowany panel
                    administracyjny
                  </p>
                </Fade>
              </div>
            </div>
          </div>
          <LazyLoad once>
            <img
              className="shape shape_1"
              src={require("../assets/images/home-advantages-shape-1.svg")}
              alt=""
            />
          </LazyLoad>
        </section>

        <section className="home_section_offer">
          <div className="container-fluid">
            <div className="row no-gutters align-items-centr">
              <div className="col-lg-3">
                <div className="desc">
                  <h3>
                    &#60;Do kogo skierowana <br /> jest nasza of3rta/&#62;
                  </h3>
                  <p>
                    Nasza oferta skierowana jest do właścicieli stron opartych
                    na systemach WordPress oraz WooCommerce:
                  </p>
                  <Link to="/cennik/" className="btn_custom">
                    &#60;Sprawdź naszą ofertę/&#62;
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 offset-lg-1">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="inner">
                      <div className="img_wrapper">
                        <LazyLoad once>
                          <img
                            className="img-fluid"
                            src={require("../assets/images/ico_offer_basic.svg")}
                            alt=""
                          />
                        </LazyLoad>
                      </div>
                      <strong>Podstawowy</strong>
                      <p>
                        Rozwiązanie przeznaczone dla stron na których treści są
                        dodawane lub aktualizowane z małą lub średnią
                        częstotliwością do 1-2 razy w miesiącu.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner">
                      <div className="img_wrapper">
                        <LazyLoad once>
                          <img
                            className="img-fluid"
                            src={require("../assets/images/ico_offer_premium.svg")}
                            alt=""
                          />
                        </LazyLoad>
                      </div>
                      <strong>Premium</strong>
                      <p>
                        Rozwiązanie przeznaczone dla stron na których treści są
                        dodawane lub aktualizowane z dużą częstotliwością 3 lub
                        więcej razy w miesiącu.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner">
                      <div className="img_wrapper">
                        <LazyLoad once>
                          <img
                            className="img-fluid"
                            src={require("../assets/images/ico_offer_ecommerce.svg")}
                            alt=""
                          />
                        </LazyLoad>
                      </div>
                      <strong>E-Commerce</strong>
                      <p>
                        to nasz system dedykowanych rozwiązań dla sklepów
                        internetowych.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home_section_numbers">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="inner">
                  <div className="wrapper">
                    <span>186</span>
                    <p>
                      zabezpieczonych <br /> stron
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="inner">
                  <div className="wrapper">
                    <span>5000</span>
                    <p>
                      oczyszczonych <br /> linijek kodu
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="inner">
                  <div className="wrapper">
                    <span>340</span>
                    <p>
                      przeprowadzonych audytów <br /> bezpieczeństwa
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="inner">
                  <div className="wrapper">
                    <span>500</span>
                    <p>
                      zadowolonych <br /> klientów
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LazyLoad once>
            <img
              className="shape shape_1"
              src={require("../assets/images/home_numbers_shape_1.svg")}
              alt=""
            />
          </LazyLoad>
          <LazyLoad once>
            <img
              className="shape shape_2"
              src={require("../assets/images/home_numbers_shape_2.svg")}
              alt=""
            />
          </LazyLoad>
        </section>

        <section className="home_section_testimonials">
          <div className="container-fluid">
            <h2 className="home_header with_sub">&#60;Ref3r3ncje/&#62;</h2>
            <p className="home_subheader">
              Jesteśmy osobiście zaangażowani w sukces wszystkich osób i firm z
              którymi współpracujemy. Właśnie dlatego mówimy, że nie mamy
              klientów, a Partnerów.
            </p>
            <Slider {...settingsTestimonials}>
              {lastReferences.edges.map(({ node }) => (
                <div key={node.id}>
                  <Link
                    to={`/referencje/${node.slug}`}
                    className="inner_testimonial"
                  >
                    <div className="thumb">
                      <img
                        className="img-fluid"
                        src={node.acf.reference_author_image.source_url}
                        alt=""
                      />
                    </div>
                    <div
                      className="inner_text"
                      dangerouslySetInnerHTML={{
                        __html: node.acf.reference_excerpt_short,
                      }}
                    />
                    <span className="testimonial_name">
                      {node.acf.reference_author_name} <br />
                      {node.acf.reference_author_position}
                    </span>
                    <span className="testimonial_company">{node.title}</span>
                    <span className="btn_custom">&#60;Case study/&#62;</span>
                  </Link>
                </div>
              ))}
              {lastReferences.edges.map(({ node }) => (
                <div key={node.id}>
                  <Link
                    to={`/referencje/${node.slug}`}
                    className="inner_testimonial"
                  >
                    <div className="thumb">
                      <img
                        className="img-fluid"
                        src={node.acf.reference_author_image.source_url}
                        alt=""
                      />
                    </div>
                    <div
                      className="inner_text"
                      dangerouslySetInnerHTML={{
                        __html: node.acf.reference_excerpt_short,
                      }}
                    />
                    <span className="testimonial_name">
                      {node.acf.reference_author_name} <br />
                      {node.acf.reference_author_position}
                    </span>
                    <span className="testimonial_company">{node.title}</span>
                    <span className="btn_custom">&#60;Case study/&#62;</span>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
          <LazyLoad once>
            <img
              className="shape"
              src={require("../assets/images/home_testimonials_shape.svg")}
              alt=""
            />
          </LazyLoad>
        </section>

        <section className="home_section_blog">
          <div className="container-fluid">
            <h2 className="home_header">&#60;B4og/&#62;</h2>

            <div className="row">
              {lastPosts.edges.map(({ node }) => (
                <div className="col-lg-4 col-sm-6" key={node.id}>
                  <div className="blog_post">
                    <Link to={`/blog/${node.slug}`}>
                      <div className="thumb_wrapper">
                        <LazyLoad once height={400}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${
                                node.better_featured_image
                                  ? node.better_featured_image.source_url
                                  : ""
                              })`,
                            }}
                          ></div>
                        </LazyLoad>
                      </div>
                    </Link>
                    <Link to={`/blog/${node.slug}`}>
                      <p dangerouslySetInnerHTML={{ __html: node.title }} />
                    </Link>
                    <Link to={`/blog/${node.slug}`} className="btn_custom">
                      &#60;Więcej/&#62;
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        lastPosts: allWordpressPost(limit: 3) {
          edges {
            node {
              id
              slug
              path
              title
              content
              better_featured_image {
                source_url
              }
              excerpt
              date
              author {
                id
                name
              }
            }
          }
        }
        lastReferences: allWordpressWpReferences {
          edges {
            node {
              id
              title
              content
              slug
              acf {
                reference_author_name
                reference_excerpt_short
                reference_author_position
                reference_author_image {
                  source_url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <IndexPage {...data} />}
  />
)
